import axios from 'axios';

import { APIError, UnsupportedStructureError } from '~services/Errors';

import {
  Campaign,
  CampaignPredictiveSettings,
  CampaignPreviewSettings,
  CampaignTrunk,
  CreateLeadList,
  DiallerGroupSipQueue,
  Disposition,
  DispositionAttributeItem,
  EndpointSequence,
  EndpointSequenceResponse,
  GetCampaignResponseDecoder,
  GetDiallerGroupSipQueuesResponseDecoder,
  GetEndpointSequenceResponseDecoder,
  GetLeadListResponseDecoder,
  GetListImportResponseDecoder,
  GetPublicHolidaysResponseDecoder,
  GetUploadLeadListResponseDecoder,
  LeadEngineSettings,
  LeadList,
  LeadListUploadResponse,
  ListImportItem,
  ListRoutingProfileResponseDecoder,
  PublicHoliday,
  RoutingProfile,
  SmsSettings,
  UpdateCampaignGeneralSettings,
  UpdateDisposition,
  UpdateLeadListGeneralSettings,
  WashList,
  WashListResponseDecoder,
} from './domain';

export const triggerDiallerGroupReload = async (diallerURL: string): Promise<void> => {
  const path = diallerURL.replace(/^ws/, 'http') + `/group-reload`;

  try {
    await axios.request({
      method: 'POST',
      url: path,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getCampaignById = async (campaignId: number): Promise<Campaign | undefined> => {
  const path = `/api/campaign/${campaignId}`;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      if (e.response!.status === 404) {
        return undefined;
      } else {
        // Response should always be defined if axios error
        throw new APIError(e.response!.status, e.message);
      }
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetCampaignResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const createCampaignDisposition = async (campaignId: number, data: Disposition): Promise<void> => {
  const path = `/api/campaign/${campaignId}/dispositions`;
  const body = {
    code: data.code,
    sub_code: data.subCode,
    title: data.title,
    outcome: data.outcome,
    description: data.description,
    attributes: data.attributes.map((attr: DispositionAttributeItem) => ({
      attribute: attr.attribute,
      value: attr.value,
    })),
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateCampaignDisposition = async (campaignId: number, data: UpdateDisposition): Promise<void> => {
  const path = `/api/campaign/${campaignId}/dispositions`;
  const body = {
    original_disposition_code: data.originalDispositionCode,
    original_disposition_sub_code: data.originalDispositionSubCode,
    disposition: {
      code: data.disposition.code,
      sub_code: data.disposition.subCode,
      title: data.disposition.title,
      outcome: data.disposition.outcome,
      description: data.disposition.description,
      attributes: data.disposition.attributes.map((attr: DispositionAttributeItem) => ({
        attribute: attr.attribute,
        value: attr.value,
      })),
    },
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const deleteCampaignDispositionByCode = async (
  campaignId: number,
  dispositionCode: string,
  dispositionSubCode: string,
) => {
  const path = `/api/campaign/${campaignId}/dispositions/${dispositionCode}/${dispositionSubCode}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateCampaignGeneralDetailsById = async (
  campaignId: number,
  data: UpdateCampaignGeneralSettings,
): Promise<void> => {
  const path = `/api/campaign/${campaignId}/`;

  const body = {
    name: data.name,
    description: data.description,
    is_active: data.isActive,
    routing_profiles: data.routingProfiles,
    default_list_id: data.defaultListId,
  };

  try {
    await axios.request({
      method: 'PATCH',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateCampaignPreviewSettingsById = async (
  campaignId: number,
  data: CampaignPreviewSettings,
): Promise<void> => {
  const path = `/api/campaign/${campaignId}/`;

  const body = {
    preview_settings: {
      agent_assigned_timeout_seconds: data.agentAssignedTimeoutSeconds,
    },
  };

  try {
    await axios.request({
      method: 'PATCH',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateCampaignTrunks = async (campaignId: number, data: CampaignTrunk[] | null): Promise<void> => {
  const path = `/api/campaign/${campaignId}/`;

  const body: { campaign_trunks: CampaignTrunk[] | null | null[] } = {
    campaign_trunks: data,
  };

  if (data === null) {
    // Golang. Don't ask.
    body.campaign_trunks = [null];
  }

  try {
    await axios.request({
      method: 'PATCH',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateCampaignPredictiveSettingsById = async (
  campaignId: number,
  data: CampaignPredictiveSettings,
): Promise<void> => {
  const path = `/api/campaign/${campaignId}/`;

  const body = {
    predictive_settings: {
      leave_message_on_attempt: data.leaveMessageOnAttempt,
      answering_machine_message: data.answeringMachineMessage,
      all_callbacks_as_preview: data.allCallbacksAsPreview,
      trunk: data.trunk,
      source_phone_number: data.sourcePhoneNumber,
    },
  };

  try {
    await axios.request({
      method: 'PATCH',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateCampaignPredictiveSettingsByIdDialler = async (
  groupId: number,
  diallerURL: string,
  data: CampaignPredictiveSettings,
): Promise<void> => {
  // TODO(RJ): remove protocol substitution hack, update config instead
  const path = diallerURL.replace(/^ws/, 'http') + `/group/${groupId}/settings`;
  const body = {
    groupId: groupId,
    trunk: data.trunk,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateCampaignLeadEngineSettingsById = async (
  campaignId: number,
  data: LeadEngineSettings,
): Promise<void> => {
  const path = `/api/campaign/${campaignId}/`;

  const body = {
    lead_engine_settings: {
      callback_reschedule_seconds: data.callbackRescheduleSeconds,
      callback_max_reschedules: data.callbackMaxReschedules,
      reassign_missed_callback_to_group: data.reassignMissedCallbackToGroup,
      callback_shift_lead_expiry: data.callbackShiftLeadExpiry,
      duplicate_search_all_campaigns: data.duplicateSearchAllCampaigns,
      wash_endpoint_only: data.washEndpointOnly,
      dialling_hour_profile: data.diallingHourProfile,
      duplicate_search_days: data.duplicateSearchDays,
      endpoint_attempt_interval_seconds: data.endpointAttemptIntervalSeconds,
      endpoint_machine_interval_seconds: data.endpointMachineIntervalSeconds,
      endpoint_engaged_interval_seconds: data.endpointEngagedIntervalSeconds,
      endpoint_no_answer_interval_seconds: data.endpointNoAnswerIntervalSeconds,
      endpoint_daily_max_attempts: data.endpointDailyMaxAttempts,
      external_id_attempt_interval_seconds: data.externalIdAttemptIntervalSeconds,
      external_id_daily_max_attempts: data.externalIdDailyMaxAttempts,
      lead_endpoint_total_max_attempts: data.leadEndpointTotalMaxAttempts,
      lead_total_max_attempts: data.leadTotalMaxAttempts,
      allow_contacted_duplication: data.allowContactedDuplication,
      sort_attempt_count_first: data.sortAttemptCountFirst,
      wash_lists: data.washLists,
      exclusion_lists: data.exclusionLists,
    },
  };

  try {
    await axios.request({
      method: 'PATCH',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateCampaignSmsSettingsById = async (campaignId: number, data: SmsSettings): Promise<void> => {
  const path = `/api/campaign/${campaignId}/`;

  const body = {
    sms_settings: {
      enabled: data.enabled,
      sms_provider: data.smsProvider || undefined,
      reply_endpoint: data.replyEndpoint || undefined,
      from_endpoint: data.fromEndpoint || undefined,
      reply_grace_time_seconds: data.replyGraceTimeSeconds ?? undefined,
      mid_journey_attempts: data.midJourneyAttempts ?? undefined,
      initial_template: data.initialTemplate || undefined,
      mid_journey_template: data.midJourneyTemplate || undefined,
      final_journey_template: data.finalJourneyTemplate || undefined,
    },
  };

  try {
    await axios.request({
      method: 'PATCH',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const archiveCampaignById = async (campaignId: number): Promise<void> => {
  const path = `/api/campaign/${campaignId}/`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const createLeadsListForCampaign = async (campaignId: number, data: CreateLeadList): Promise<void> => {
  const path = `/api/campaign/${campaignId}/list`;

  const body = {
    name: data.name,
    description: data.description,
    priority: data.priority,
    start_time: data.startTime,
    end_time: data.endTime,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const toggleLeadsListActiveById = async (campaignId: number, listId: number, isActive: boolean) => {
  const path = `/api/campaign/${campaignId}/list/${listId}`;

  const body = {
    is_active: isActive,
  };

  try {
    await axios.request({
      method: 'PATCH',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const moveLeadsListCampaign = async (newCampaignId: number, listId: number) => {
  const path = `/api/list/${listId}`;

  const body = {
    new_campaign_id: newCampaignId,
  };

  try {
    await axios.request({
      method: 'PATCH',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateLeadsListById = async (campaignId: number, listId: number, data: UpdateLeadListGeneralSettings) => {
  const path = `/api/campaign/${campaignId}/list/${listId}`;

  const body = {
    name: data.name,
    description: data.description,
    priority: data.priority,
    is_active: data.isActive,
    start_time: data.startTime,
    end_time: data.endTime,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data: body,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getLeadsListById = async (campaignId: number, listId: number): Promise<LeadList | undefined> => {
  const path = `/api/campaign/${campaignId}/list/${listId}`;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      if (e.response!.status === 404) {
        return undefined;
      } else {
        // Response should always be defined if axios error
        throw new APIError(e.response!.status, e.message);
      }
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetLeadListResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const uploadLeadsListFileForList = async (
  campaignId: number,
  listId: number,
  file: File,
  onUploadProgress: (progress: number) => void,
): Promise<LeadListUploadResponse> => {
  const path = `/api/campaign/${campaignId}/list/${listId}/upload`;
  let resp;

  const formData = new FormData();
  formData.append('file', file);

  try {
    resp = await axios.request({
      method: 'POST',
      url: path,
      data: formData,
      onUploadProgress: (progressEvent) => {
        const total = progressEvent.total ?? 0;
        const percentCompleted = Math.round((progressEvent.loaded * 100) / total);
        onUploadProgress(percentCompleted);
      },
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetUploadLeadListResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getListImportDataById = async (
  campaignId: number,
  listId: number,
  importId: number,
): Promise<ListImportItem[]> => {
  const path = `/api/campaign/${campaignId}/list/${listId}/imports/${importId}`;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetListImportResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const applyImportList = async (
  campaignId: number,
  listId: number,
  importId: number,
): Promise<LeadListUploadResponse> => {
  const path = `/api/campaign/${campaignId}/list/${listId}/imports/${importId}`;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetUploadLeadListResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const discardImportList = async (campaignId: number, listId: number, importId: number): Promise<void> => {
  const path = `/api/campaign/${campaignId}/list/${listId}/imports/${importId}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getRoutingProfiles = async (): Promise<RoutingProfile[]> => {
  const path = `/api/routing-profiles/`;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = ListRoutingProfileResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getWashLists = async (): Promise<WashList[]> => {
  let resp;

  try {
    resp = await axios.request({
      method: 'GET',
      url: '/api/wash-lists',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = WashListResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    throw new UnsupportedStructureError(decoded.error.message);
  }

  return decoded.result;
};

export const getPublicHolidays = async (timezone: string): Promise<PublicHoliday[]> => {
  const path = `/api/public-holidays/?timezone=${timezone}`;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetPublicHolidaysResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const getEndpointSequence = async (campaignId: number): Promise<EndpointSequenceResponse> => {
  const path = `/api/campaign/${campaignId}/endpoint-sequence`;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetEndpointSequenceResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const putEndpointSequence = async (campaignId: number, endpointSequences: EndpointSequence[]): Promise<void> => {
  const path = `/api/campaign/${campaignId}/endpoint-sequence`;

  try {
    await axios.request({
      url: path,
      method: 'PUT',
      data: {
        endpoint_sequences: endpointSequences.map((seq) => ({
          endpoint_type: seq.endpointType,
          start_attempt: seq.start,
          priority: seq.priority,
        })),
      },
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const deleteEndpointSequence = async (campaignId: number): Promise<void> => {
  const path = `/api/campaign/${campaignId}/endpoint-sequence`;

  try {
    await axios.request({
      url: path,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const getDiallerGroupQueues = async (
  diallerGroupId: string,
  search: string,
): Promise<DiallerGroupSipQueue[]> => {
  const path = `/api/group/${diallerGroupId}/queues`;
  let resp;

  try {
    resp = await axios.request({
      url: path,
      method: 'GET',
      params: {
        search,
      },
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }

  const decoded = GetDiallerGroupSipQueuesResponseDecoder.run(resp.data);

  if (decoded.ok === false) {
    const err = new UnsupportedStructureError(decoded.error.message);

    console.error(decoded.error);
    console.error(err);
    throw err;
  }

  return decoded.result;
};

export const addDiallerGroupQueue = async (diallerGroupId: string, queue: DiallerGroupSipQueue): Promise<void> => {
  const path = `/api/group/${diallerGroupId}/queues`;

  const data = {
    queue_id: queue.queueID,
    queue_name: queue.queueName,
    priority: queue.priority,
    delay_second: queue.delay,
  };

  try {
    await axios.request({
      method: 'POST',
      url: path,
      data,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const updateDiallerGroupQueue = async (diallerGroupId: string, queue: DiallerGroupSipQueue): Promise<void> => {
  const path = `/api/group/${diallerGroupId}/queues/${queue.queueID}`;

  const data = {
    queue_id: queue.queueID,
    queue_name: queue.queueName,
    priority: queue.priority,
    delay_second: queue.delay,
  };

  try {
    await axios.request({
      method: 'PUT',
      url: path,
      data,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};

export const removeDiallerGroupQueue = async (diallerGroupId: string, queueId: number): Promise<void> => {
  const path = `/api/group/${diallerGroupId}/queues/${queueId}`;

  try {
    await axios.request({
      method: 'DELETE',
      url: path,
      headers: {
        Accept: 'application/json',
      },
    });
  } catch (e) {
    if (axios.isAxiosError(e)) {
      // Response should always be defined if axios error
      throw new APIError(e.response!.status, e.message);
    }

    throw new APIError(-1, e as string);
  }
};
