import * as j from '@mojotech/json-type-validation';

import { Lead, LeadAttempt, LeadStatusType } from '~pages/CampaignManagement/domain';
import { AttemptCreationContext } from '~providers/AttemptProvider/domain';

export type FilterOptions = {
  search?: string;
  leadStatus?: LeadStatusType[];
};

export type LeadBulkActionOptions = {
  selectAll: boolean;
  // Agents to be added, if selectAll is true, agents to add additional to the select all criteria
  leadIds: number[];
  // If selectAll is true, agents that match the select all criteria that should be excluded
  excludedLeadIds: number[];
  search?: string;
  leadStatus?: string[];
};

export interface LeadsResponse {
  nextPageUrl: string | null;
  totalLeads: number;
  list: Lead[];
}

const GetLeadItemResponseDecoder: j.Decoder<Lead> = j
  .object({
    lead_id: j.number(),
    list_id: j.number(),
    campaign_id: j.number(),
    lead_external_id: j.string(),
    lead_name: j.string(),
    lead_status: j.oneOf(
      j.constant(LeadStatusType.Assigned),
      j.constant(LeadStatusType.AwaitingRetry),
      j.constant(LeadStatusType.AwaitingStart),
      j.constant(LeadStatusType.Building),
      j.constant(LeadStatusType.Callback),
      j.constant(LeadStatusType.Connected),
      j.constant(LeadStatusType.Contacted),
      j.constant(LeadStatusType.Duplicate),
      j.constant(LeadStatusType.Excluded),
      j.constant(LeadStatusType.Filtered),
      j.constant(LeadStatusType.Finished),
      j.constant(LeadStatusType.FinishedToday),
      j.constant(LeadStatusType.Initiated),
      j.constant(LeadStatusType.NoEndpoints),
      j.constant(LeadStatusType.NoSkilledAgents),
      j.constant(LeadStatusType.OptOut),
      j.constant(LeadStatusType.OutOfHours),
      j.constant(LeadStatusType.Ready),
      j.constant(LeadStatusType.Washed),
      j.constant(LeadStatusType.Disconnected),
      j.constant(LeadStatusType.InvalidEndpoint),
      j.constant(LeadStatusType.Removed),
      j.constant(LeadStatusType.Expired),
      j.constant(LeadStatusType.AwaitingSMS),
      j.constant(LeadStatusType.InactiveList),
      j.constant(LeadStatusType.MissedCallback),
      j.constant(LeadStatusType.InQueue),
      j.constant(LeadStatusType.AwaitingCallback),
      j.constant(LeadStatusType.Replaced),
    ),
    lead_status_detail: j.string(),
    priority: j.union(j.number(), j.constant(null)),
    timezone: j.string(),
    added_timestamp: j.union(j.string(), j.constant(null)),
    start_timestamp: j.union(j.string(), j.constant(null)),
    expiration_timestamp: j.union(j.string(), j.constant(null)),
    recheck_timestamp: j.union(j.string(), j.constant(null)),
    endpoints: j.array(j.string()),
    num_attempts: j.number(),
    attempts: j.array(
      j.object({
        timestamp: j.string(),
        outcome: j.union(j.string(), j.constant(null)),
        disposition_code: j.union(j.string(), j.constant(null)),
        disposition_sub_code: j.union(j.string(), j.constant(null)),
        contact_id: j.union(j.string(), j.constant(null)),
        callback_id: j.union(j.number(), j.constant(null)),
        endpoint: j.string(),
        creation_context: j.string(),
      }),
    ),
    required_skills: j.array(j.string()),
    attributes: j.union(j.dict(j.string()), j.constant(null)),
  })
  .map((item: any) => ({
    id: item.lead_id,
    listId: item.list_id,
    campaignId: item.camapignId,
    externalId: item.lead_external_id,
    name: item.lead_name,
    leadStatus: item.lead_status,
    leadStatusDetail: item.lead_status_detail,
    priority: item.priority,
    timezone: item.timezone,
    addedTimestamp: item.added_timestamp,
    startTimestamp: item.start_timestamp,
    expirationTimestamp: item.expiration_timestamp,
    recheckTimestamp: item.recheck_timestamp,
    endpoints: item.endpoints,
    numAttempts: item.num_attempts,
    attempts: item.attempts.map(
      (attempt: any): LeadAttempt => ({
        timestamp: attempt.timestamp,
        outcome: attempt.outcome,
        dispositionCode: attempt.disposition_code,
        dispositionSubCode: attempt.disposition_sub_code,
        contactId: attempt.contact_id,
        callbackId: attempt.callback_id,
        endpoint: attempt.endpoint,
        creationContext: attempt.creation_context as AttemptCreationContext,
      }),
    ),
    requiredSkills: item.required_skills,
    attributes: item.attributes,
    endpointAttributes: {},
  }));

export const GetLeadsResponseDecoder: j.Decoder<LeadsResponse> = j
  .object({
    next_page_url: j.union(j.string(), j.constant(null)),
    total_leads: j.number(),
    list: j.array(GetLeadItemResponseDecoder),
  })
  .map((item: any) => ({
    nextPageUrl: item.next_page_url,
    totalLeads: item.total_leads,
    list: item.list,
  }));
