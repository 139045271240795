import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { ChangeEvent, FocusEvent, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { DotLoader } from '~components/DotLoader';
import OberonDialog from '~components/OberonDialog';
import useDebounce from '~hooks/useDebounce';
import useSystemEndpointList from '~hooks/useSystemEndpointList';
import { getRoutingProfiles } from '~pages/CampaignManagement/api';
import useAccessFilterSearch from '~pages/SystemManagement/AccessFilterList/useAccessFilterSearch';
import { useUserPreferences } from '~providers/UserPreferencesProvider';

import { CreateCampaign, RoutingProfile } from '../../domain';

interface CreateCampaignModalProps {
  open: boolean;
  submitting: boolean;
  onAccept: (data: CreateCampaign) => void;
  onClose: () => void;
}

interface ListItem {
  id: number;
  name: string;
}

interface Form {
  name: string;
  description: string;
  routingProfiles: { label: string; value: string }[];
  sourcePhoneNumber: string;
  accessFilters: ListItem[];
}

const fetchRoutingProfilesOrEmptyArray = async () => {
  try {
    return await getRoutingProfiles();
  } catch (e) {
    return [];
  }
};

const CreateCampaignModal = ({ open, submitting, onAccept, onClose }: CreateCampaignModalProps) => {
  const { accessFilter } = useUserPreferences();
  const [fetchingFormData, setFetchingFormData] = useState<boolean>(false);
  const [routingProfiles, setRoutingProfiles] = useState<RoutingProfile[]>([]);
  const [searchAccessFilter, setSearchAccessFilter] = useState<string>('');
  const debouncedSearchAccessFilter = useDebounce(searchAccessFilter, 500);
  const {
    loading: accessFilterFetching,
    error: accessFilterFetchError,
    list: accessFilters,
    intersectionObserverRef: lastAccessFilterDataElement,
  } = useAccessFilterSearch(debouncedSearchAccessFilter, { archived: false, shouldFetch: open });
  const {
    loading: fetchingEndpoints,
    list: endpoints,
    intersectionObserverRef,
  } = useSystemEndpointList({ shouldFetch: open });
  const {
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
  } = useForm<Form>({
    defaultValues: {
      name: '',
      description: '',
      routingProfiles: [],
      sourcePhoneNumber: '',
      accessFilters: [],
    },
    mode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
  });
  const isLoading = fetchingFormData || submitting || accessFilterFetching || fetchingEndpoints;
  const accessFiltersWatch = watch('accessFilters');

  // Manages form based data
  useEffect(() => {
    const fetchRoutingProfiles = async () => {
      setFetchingFormData(true);

      const data = await fetchRoutingProfilesOrEmptyArray();
      setRoutingProfiles(data);

      setFetchingFormData(false);
    };

    if (open) {
      fetchRoutingProfiles();
      if (accessFilter) {
        setValue('accessFilters', [{ id: accessFilter.id, name: accessFilter.name }]);
      }
    }

    return () => {
      reset();
    };
  }, [open]);

  const onSubmit = handleSubmit(async (data: Form) => {
    let submitData: CreateCampaign = {
      name: data.name,
      description: data.description,
      routingProfiles: data.routingProfiles.map((item: { label: string; value: string }) => item.value),
      accessFilterIds: data.accessFilters.length > 0 ? data.accessFilters.map((item) => item.id) : undefined,
      predictiveSettings: {
        leaveMessageOnAttempt: undefined,
        answeringMachineMessage: undefined,
        allCallbacksAsPreview: undefined,
        trunk: undefined,
        sourcePhoneNumber: data.sourcePhoneNumber,
      },
    };

    try {
      await onAccept(submitData);
    } catch (e) {
      // Do nothing, catch error to prevent form reset on failed action
      return;
    }

    reset();
  });

  const routingProfilesList = routingProfiles.map((item: RoutingProfile) => ({
    label: item.name,
    value: item.id,
  }));

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchAccessFilter(e.target.value);
  };

  const onSearchBlur = (e: FocusEvent<HTMLInputElement>) => {
    setSearchAccessFilter('');
  };

  const accessFiltersNoOptionsText = useMemo(() => {
    if (accessFilterFetching) {
      return <DotLoader align='center' />;
    }

    if (accessFilterFetchError) {
      return (
        <Typography variant='body2' align='center' color='textSecondary'>
          Failed to load access filters
        </Typography>
      );
    }

    return undefined;
  }, [accessFilterFetching, accessFilterFetchError]);

  const accessFiltersFilteredList: ListItem[] = useMemo(() => {
    let list: ListItem[] = accessFilters.map((item) => ({ id: item.id, name: item.name }));
    if (accessFilter) {
      list = [...list, { id: accessFilter.id, name: accessFilter.name }];
    }
    if (!accessFiltersWatch) {
      return list;
    }

    return list.filter((listItem) => {
      return !accessFiltersWatch.find((watchItem) => listItem.id === watchItem.id);
    });
  }, [accessFilters, accessFiltersWatch]);

  return (
    <OberonDialog
      open={open}
      onSubmit={onSubmit}
      onClose={onClose}
      title='Create Campaign'
      content={
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Controller
              name='name'
              control={control}
              rules={{
                required: 'Campaign name is required.',
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Campaign Name'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='description'
              control={control}
              rules={{
                required: 'Campaign description is required.',
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant='outlined'
                  label='Campaign Description'
                  disabled={isLoading}
                  required={true}
                  error={Boolean(errors.description)}
                  helperText={errors.description?.message}
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='routingProfiles'
              control={control}
              rules={{ required: 'At least one routing profile must be selected.' }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  value={field.value}
                  onChange={(e, data) => field.onChange(data)}
                  fullWidth
                  multiple
                  options={routingProfilesList}
                  filterSelectedOptions
                  disabled={isLoading}
                  renderInput={(params) => (
                    <TextField
                      label='Routing Profiles'
                      required={true}
                      error={Boolean(errors.routingProfiles)}
                      helperText={errors.routingProfiles?.message}
                      variant='outlined'
                      {...params}
                    />
                  )}
                />
              )}
            />
          </Grid>

          {/* it's not clear yet if we can require a connect source phone number. */}
          <Grid item xs={12}>
            <Controller
              name='sourcePhoneNumber'
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  options={endpoints}
                  disabled={isLoading}
                  disableClearable
                  getOptionLabel={(option) => option || ''}
                  renderOption={(props, option) => (
                    <li {...props} ref={intersectionObserverRef} key={option}>
                      <Typography variant='body1' color='textPrimary' component='p'>
                        {option}
                      </Typography>
                    </li>
                  )}
                  renderInput={({ inputProps, ...rest }) => (
                    <TextField
                      {...rest}
                      label='Source Phone Number'
                      variant='outlined'
                      error={Boolean(errors.sourcePhoneNumber)}
                      helperText={errors.sourcePhoneNumber?.message}
                      inputProps={{ ...inputProps, readOnly: true }}
                    />
                  )}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='accessFilters'
              control={control}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  fullWidth
                  multiple
                  onChange={(e, data) => {
                    field.onChange(data);
                  }}
                  options={accessFiltersFilteredList}
                  noOptionsText={accessFiltersNoOptionsText}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  disabled={isLoading}
                  getOptionLabel={(option) => option.name || ''}
                  renderOption={(props, option) => (
                    <li {...props} ref={lastAccessFilterDataElement} key={option.id}>
                      <Typography variant='body1' color='textPrimary' component='p'>
                        {option.name}
                      </Typography>
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Access Filters'
                      variant='outlined'
                      onBlur={onSearchBlur}
                      onChange={onSearchChange}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      }
      actionFooter={
        <>
          <Button variant='text' disabled={isLoading} onClick={onClose}>
            Close
          </Button>

          <LoadingButton
            type='submit'
            variant='contained'
            disableElevation
            color='primary'
            disabled={isLoading}
            loading={isLoading}>
            Create
          </LoadingButton>
        </>
      }
    />
  );
};

export default CreateCampaignModal;
