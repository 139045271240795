import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AsyncLoader from '~components/AsyncLoader';
import ConversationTranscript from '~components/ConversationTranscript';
import EmptyState from '~components/EmptyState';
import SectionCard from '~components/SectionCard';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useSetPageTitleProps } from '~providers/PageTitleProvider';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import { getContactDetails } from '../api';
import { ContactDetails as ContactDetailsInterface } from '../domain';
import ContactAssessment from './ContactAssessment';
import ContactCallMetrics from './ContactCallMetrics';
import ContactChatTranscript from './ContactChatTranscript';
import ContactRecording from './ContactRecording';
import ContactScreenRecording from './ContactScreenRecording';
import ContactSummary from './ContactSummary';

interface Error {
  text: string;
  subText: string;
}

const ContactDetails = () => {
  const { extensions } = useAppConfiguration();
  const { hasScope } = useAuth();
  const setPageTitleProps = useSetPageTitleProps();
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const { contactId } = useParams() as { contactId: string };
  const [error, setError] = useState<Error | null>(null);
  const [contactDetails, setContactDetails] = useState<ContactDetailsInterface>();
  const isVoice = contactDetails?.channel === 'VOICE';
  const isChat = contactDetails?.channel === 'CHAT';
  const isAsync = contactDetails?.initiationMethod === 'ASYNC';
  const screenRecordingExtensionEnabled = Boolean(extensions.screenRecordings);

  // Set page title
  useEffect(() => {
    setPageTitleProps({ pageName: contactId });
  }, []);

  useEffect(() => {
    (async () => {
      await fetchContactDetails();
    })();
  }, [contactId]);

  const fetchContactDetails = async () => {
    try {
      setContactDetails(await getContactDetails(contactId));
    } catch (e) {
      handleError(e);
      return;
    } finally {
      setInitialLoad(false);
    }
  };

  const handleError = (e: any) => {
    console.error(e);
    if (e instanceof APIError) {
      setError({ text: 'Unable to request data from backend', subText: e.message });
    }
    if (e instanceof UnsupportedStructureError) {
      setError({ text: 'Data from backend Invalid', subText: 'Unable to decode response' });
    }
  };

  const errorDisplay = error ? <EmptyState type='error' text={error.text} subText={error.subText} /> : undefined;

  return (
    <AsyncLoader isLoading={initialLoad} error={errorDisplay}>
      {contactDetails && <ContactSummary contactDetails={contactDetails} />}

      {contactDetails && isVoice && (
        <ContactRecording
          recordingLocation={contactDetails.transcriptLocation}
          canDownload={hasScope(AccessScope.CanDownloadCallRecordings)}
          canView={hasScope(AccessScope.CanViewCallRecordings)}
        />
      )}

      {contactDetails && isChat && (
        <ContactChatTranscript
          transcriptLocation={contactDetails.transcriptLocation}
          canView={hasScope(AccessScope.CanViewChatTranscripts)}
        />
      )}

      {contactDetails && hasScope(AccessScope.CanViewChatTranscripts) && isAsync && (
        <SectionCard title='Contact Summary' disablePadding>
          <ConversationTranscript conversationUUID={contactDetails.contactId} />
        </SectionCard>
      )}

      {/* 'TODO: List of recordings' */}
      {screenRecordingExtensionEnabled && contactDetails && (
        <ContactScreenRecording
          contactId={contactId}
          recordingInfoList={contactDetails.recordingInfoList}
          canDownload={hasScope(AccessScope.CanDownloadScreenRecordings)}
          canView={hasScope(AccessScope.CanViewScreenRecordings)}
        />
      )}

      {contactDetails && (
        <ContactAssessment
          contactId={contactId}
          campaignId={contactDetails.campaignId}
          canView={hasScope(AccessScope.CanViewContactAssessment)}
          assessmentDetails={contactDetails.assessmentDetails}
          triggerContactLogRefresh={fetchContactDetails}
        />
      )}

      {contactDetails && isVoice && contactDetails.metrics.length > 0 && (
        <ContactCallMetrics metrics={contactDetails.metrics} canView={hasScope(AccessScope.CanViewCallMetrics)} />
      )}
    </AsyncLoader>
  );
};

export default ContactDetails;
