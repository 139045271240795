import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ActionDialog from '~components/ActionDialog';
import SectionCard from '~components/SectionCard';
import { useAppConfiguration } from '~providers/AppConfigurationProvider';
import { useAuth } from '~providers/AuthProvider';
import { AccessScope } from '~providers/AuthProvider/domain';
import { useNotification } from '~providers/NotificationProvider';
import Routes from '~providers/RouteProvider/Routes';
import { APIError, UnsupportedStructureError } from '~services/Errors';

import {
  archiveCampaignById,
  triggerDiallerGroupReload,
  updateCampaignGeneralDetailsById,
  updateCampaignLeadEngineSettingsById,
  updateCampaignPredictiveSettingsById,
  updateCampaignPredictiveSettingsByIdDialler,
  updateCampaignPreviewSettingsById,
  updateCampaignSmsSettingsById,
  updateCampaignTrunks,
} from '../../api';
import {
  Campaign,
  CampaignPredictiveSettings,
  CampaignPreviewSettings,
  CampaignTrunk,
  LeadEngineSettings,
  RoutingProfile,
  SmsSettings,
  UpdateCampaignGeneralSettings,
} from '../../domain';
import GeneralSettingsForm from './GeneralSettingsForm';
import LeadEngineSettingsForm from './LeadEngineSettingsForm';
import PredictiveSettingsForm from './PredictiveSettingsForm';
import PreviewSettingsForm from './PreviewSettingsForm';
import SMSSettingsForm from './SMSSettingsForm';
import TrunksForm from './TrunksForm';

const enum EditType {
  General = 'general',
  PredictiveSettings = 'predictive-settings',
  PreviewSettings = 'preview-settings',
  LeadEngineSettings = 'lead-engine-settings',
  SmsSettings = 'sms-settings',
  Trunks = 'trunks',
}

interface CampaignConfigurationProps {
  campaign: Campaign;
  triggerCampaignRefresh: () => Promise<void>;
  routingProfiles: RoutingProfile[];
}

const CampaignConfiguration = ({ campaign, triggerCampaignRefresh, routingProfiles }: CampaignConfigurationProps) => {
  const { hasScope } = useAuth();
  const appConfig = useAppConfiguration();
  const navigate = useNavigate();
  const { campaignId } = useParams() as { campaignId: string };
  const { pushNotification } = useNotification();
  const [open, setOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<EditType | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const hasSmsSettings = Boolean(campaign.previewSettings || campaign.predictiveSettings);
  const canArchiveCampaigns = hasScope(AccessScope.CanArchiveCampaign);

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const toggleEdit = (value: EditType) => () => {
    setEdit((prev) => (prev === value ? undefined : value));
  };

  const archiveCampaign = async () => {
    try {
      await archiveCampaignById(+campaignId);
    } catch (e) {
      pushNotification('error', 'Failed to archive campaign');
      return;
    }

    if (appConfig.extensions.predictive !== undefined) {
      try {
        await triggerDiallerGroupReload(appConfig.extensions.predictive.diallerURL);
      } catch (e) {
        console.error('! Failed to trigger dialler group reload for predictive dialler.');
      }
    }

    navigate(Routes.diallerConfig.path);
    pushNotification('success', `The campaign ${campaign.name} has been archived`);
  };

  const onGeneralSettingsSubmit = async (data: UpdateCampaignGeneralSettings) => {
    setSubmitting(true);

    try {
      await updateCampaignGeneralDetailsById(+campaignId, data);
    } catch (e) {
      setSubmitting(false);
      pushNotification('error', 'Failed to update general settings.');
      return;
    }

    if (appConfig.extensions.predictive !== undefined) {
      try {
        await triggerDiallerGroupReload(appConfig.extensions.predictive.diallerURL);
      } catch (e) {
        setSubmitting(false);
        pushNotification('error', 'Failed to trigger dialler group reload for predictive dialler.');
        return;
      }
    }

    await triggerCampaignRefresh();
    setSubmitting(false);
    pushNotification('success', 'You have successfully updated the campaign.');
    toggleEdit(EditType.General)();
  };

  const onPreviewSettingsSubmit = async (data: CampaignPreviewSettings) => {
    setSubmitting(true);

    try {
      await updateCampaignPreviewSettingsById(+campaignId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return Promise.reject();
    } finally {
      setSubmitting(false);
    }

    pushNotification('success', 'You have successfully updated the campaign.');
    toggleEdit(EditType.PreviewSettings)();
    triggerCampaignRefresh();
  };

  const onPredictiveSettingsSubmit = async (data: CampaignPredictiveSettings) => {
    setSubmitting(true);

    if (appConfig.extensions.predictive === undefined) {
      console.error('onPredictiveSettingsFormSubmit: Unable to get predictive extension configuration');
      return;
    }

    const predictiveDiallerUrl = appConfig.extensions.predictive.diallerURL;

    try {
      await Promise.all([
        updateCampaignPredictiveSettingsById(+campaignId, data),
        updateCampaignPredictiveSettingsByIdDialler(+campaignId, predictiveDiallerUrl, data),
      ]);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return Promise.reject();
    } finally {
      setSubmitting(false);
    }

    pushNotification('success', 'You have successfully updated the campaign.');
    toggleEdit(EditType.PredictiveSettings)();
    triggerCampaignRefresh();
  };

  const onLeadEngineSettingsSubmit = async (data: LeadEngineSettings) => {
    setSubmitting(true);

    try {
      await updateCampaignLeadEngineSettingsById(+campaignId, data);
    } catch (e) {
      setSubmitting(false);
      pushNotification('error', 'Failed to update lead engine settings.');
      return;
    }

    if (appConfig.extensions.predictive !== undefined) {
      try {
        await triggerDiallerGroupReload(appConfig.extensions.predictive.diallerURL);
      } catch (e) {
        setSubmitting(false);
        pushNotification('error', 'Failed to trigger dialler group reload for predictive dialler.');
        return;
      }
    }

    setSubmitting(false);
    pushNotification('success', 'You have successfully updated the campaign.');
    toggleEdit(EditType.LeadEngineSettings)();
    triggerCampaignRefresh();
  };

  const onTrunksSubmit = async (data: CampaignTrunk[] | null) => {
    setSubmitting(true);

    try {
      await updateCampaignTrunks(+campaignId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return Promise.reject();
    } finally {
      setSubmitting(false);
    }

    pushNotification('success', 'You have successfully updated the trunks for the campaign.');
    toggleEdit(EditType.Trunks)();
    triggerCampaignRefresh();
  };

  const onSmsSettingsSubmit = async (data: SmsSettings) => {
    setSubmitting(true);

    try {
      await updateCampaignSmsSettingsById(+campaignId, data);
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    } finally {
      setSubmitting(false);
    }

    pushNotification('success', 'You have successfully added precall sms settings to the campaign.');
    toggleEdit(EditType.SmsSettings)();
    triggerCampaignRefresh();
  };

  const onRemoveSmsSettings = async () => {
    setSubmitting(true);

    try {
      await updateCampaignSmsSettingsById(+campaignId, {
        enabled: false,
      });
    } catch (e) {
      pushNotification('error', (e as APIError | UnsupportedStructureError).message);
      return;
    } finally {
      setSubmitting(false);
    }

    pushNotification('success', 'You have successfully removed precall sms settings from the campaign.');
    toggleEdit(EditType.SmsSettings)();
    triggerCampaignRefresh();
  };

  return (
    <>
      <GeneralSettingsForm
        campaign={campaign}
        isEdit={edit === EditType.General}
        submitting={submitting}
        toggleEdit={hasScope(AccessScope.CanUpdateCampaignGeneralSettings) ? toggleEdit(EditType.General) : undefined}
        onSubmit={onGeneralSettingsSubmit}
        routingProfiles={routingProfiles}
      />

      {campaign.previewSettings !== undefined && (
        <PreviewSettingsForm
          previewSettings={campaign.previewSettings}
          isEdit={edit === EditType.PreviewSettings}
          submitting={submitting}
          toggleEdit={
            hasScope(AccessScope.CanUpdateCampaignPreviewSettings) ? toggleEdit(EditType.PreviewSettings) : undefined
          }
          onSubmit={onPreviewSettingsSubmit}
        />
      )}

      {campaign.predictiveSettings !== undefined && (
        <PredictiveSettingsForm
          predictiveSettings={campaign.predictiveSettings}
          isEdit={edit === EditType.PredictiveSettings}
          submitting={submitting}
          toggleEdit={
            hasScope(AccessScope.CanUpdateCampaignPredictiveSettings)
              ? toggleEdit(EditType.PredictiveSettings)
              : undefined
          }
          onSubmit={onPredictiveSettingsSubmit}
        />
      )}

      {campaign.leadEngineSettings !== undefined && (
        <LeadEngineSettingsForm
          leadEngineSettings={campaign.leadEngineSettings}
          isEdit={edit === EditType.LeadEngineSettings}
          submitting={submitting}
          toggleEdit={
            hasScope(AccessScope.CanUpdateCampaignLeadEngineSettings)
              ? toggleEdit(EditType.LeadEngineSettings)
              : undefined
          }
          onSubmit={onLeadEngineSettingsSubmit}
        />
      )}

      {hasSmsSettings && (
        <SMSSettingsForm
          campaign={campaign}
          isEdit={edit === EditType.SmsSettings}
          submitting={submitting}
          toggleEdit={hasScope(AccessScope.CanUpdateCampaignSMSSettings) ? toggleEdit(EditType.SmsSettings) : undefined}
          onSubmit={onSmsSettingsSubmit}
          onRemove={onRemoveSmsSettings}
        />
      )}

      {campaign.predictiveSettings !== undefined && (
        <TrunksForm
          trunks={campaign.campaignTrunks || []}
          isEdit={edit == EditType.Trunks}
          submitting={submitting}
          toggleEdit={hasScope(AccessScope.CanUpdateCampaignTrunksSettings) ? toggleEdit(EditType.Trunks) : undefined}
          onSubmit={onTrunksSubmit}
        />
      )}

      <SectionCard title='Archive Campaign'>
        {!campaign.archived && (
          <>
            <Typography variant='body1' component='p' paragraph>
              Click below to archive this campaign
            </Typography>

            <Button
              disableElevation
              variant='contained'
              onClick={toggleOpen}
              color='secondary'
              disabled={!canArchiveCampaigns}>
              Archive
            </Button>
          </>
        )}

        {campaign.archived && (
          <Typography variant='body1' component='p' paragraph>
            This campaign has been archived as of {DateTime.fromISO(campaign.archived).toFormat('FFF')}.
          </Typography>
        )}
      </SectionCard>

      {canArchiveCampaigns && (
        <ActionDialog
          open={open}
          title='Are you sure you want to do this?'
          content='You are about to archive this campaign, once you have completed this action it cannot be undone.'
          onClose={toggleOpen}
          onAccept={archiveCampaign}
          primaryActionTitle='Archive'
        />
      )}
    </>
  );
};

export default CampaignConfiguration;
